import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-steam-deal-main',
  templateUrl: './steam-deal-main.component.html',
  styleUrls: ['./steam-deal-main.component.scss']
})
export class SteamDealMainComponent implements OnInit {

  private inviteLink = 'https://discordapp.com/api/oauth2/authorize?client_id=693782372348461067&permissions=380104665088&scope=bot%20applications.commands';

  constructor() {
  }

  ngOnInit(): void {
  }

  public clickInvite() {
    window.open(this.inviteLink, '_blank');
  }
}
