<div class="container">
  <div class="row justify-content-center mt-4">
    <h1 class="text-white font-weight-bold" *ngIf="fisherman">{{fisherman.name}}'s Collection:</h1>
  </div>
  <div class="row legendary mt-5 p-2" *ngIf="legendary.length > 0">
    <h2 class="col-12 legendary font-weight-bold">Legendary</h2>
    <app-fish-card *ngFor="let fish of legendary" [fish]="fish" class="col-md-4 col-12"></app-fish-card>
  </div>
  <div class="row epic mt-5 p-2" *ngIf="epic.length > 0">
    <h2 class="col-12 epic font-weight-bold">Epic</h2>
    <app-fish-card *ngFor="let fish of epic" [fish]="fish" class="col-md-4 col-12"></app-fish-card>
  </div>
  <div class="row rare mt-5 p-2" *ngIf="rare.length > 0">
    <h2 class="col-12 rare font-weight-bold">Rare</h2>
    <app-fish-card *ngFor="let fish of rare" [fish]="fish" class="col-md-4 col-12"></app-fish-card>
  </div>
  <div class="row uncommon mt-5 p-2" *ngIf="uncommon.length > 0">
    <h2 class="col-12 uncommon font-weight-bold">Uncommon</h2>
    <app-fish-card *ngFor="let fish of uncommon" [fish]="fish" class="col-md-4 col-12"></app-fish-card>
  </div>
  <div class="row common mt-5 p-2" *ngIf="common.length > 0">
    <h2 class="col-12 common font-weight-bold">Common</h2>
    <app-fish-card *ngFor="let fish of common" [fish]="fish" class="col-md-4 col-12"></app-fish-card>
  </div>
  <div class="row junk mt-5 p-2" *ngIf="junk.length > 0">
    <h2 class="col-12 junk font-weight-bold">Junk</h2>
    <app-fish-card *ngFor="let fish of junk" [fish]="fish" class="col-md-4 col-12"></app-fish-card>
  </div>
</div>
