import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LogoBaseComponent} from './logo-base/logo-base.component';
import {LogoStoryComponent} from './logo-story/logo-story.component';
import {LogoSteamComponent} from './logo-steam/logo-steam.component';
import {LogoDiceComponent} from './logo-dice/logo-dice.component';

@NgModule({
  declarations: [
    LogoBaseComponent,
    LogoStoryComponent,
    LogoSteamComponent,
    LogoDiceComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    LogoBaseComponent,
    LogoStoryComponent,
    LogoSteamComponent,
    LogoDiceComponent,
  ]
})
export class PageComponentsModule {
}
