export const paths = {
  baseURL: 'https://helegoeiediscordbots.nl/api',
  baseBotURL: 'https://helegoeiediscordbots.nl/bot',
  story: {
    getAllStories: '/get-stories',
    getFullStory: '/get-full-story',
    getStoryById: '/get-story-by-id',
    getStoryByIdMinimal: '/get-story-by-id-min',
    createCharacter: '/create-character',
    updateCharacter: '/update-character',
    removeCharacter: '/remove-character'
  },
  fish: {
    collection: '/fishies/collection'
  }
};
