import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StorytimeCharacter} from '../../../../entity/storytime/storytime-character';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {StoryTimeService} from '../../../../services/story-time.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActiveStoryTimeServer} from '../../../../entity/storytime/active-storytime-server';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DeleteConfirmationComponent} from '../delete-confirmation/delete-confirmation.component';
import {DiscordStory} from '../../../../entity/storytime/discord-story';

@Component({
  selector: 'app-create-character',
  templateUrl: './create-character.component.html',
  styleUrls: ['./create-character.component.scss']
})
export class CreateCharacterComponent implements OnInit {
  characterForm: FormGroup;
  public discordStory: ActiveStoryTimeServer | DiscordStory;
  public currentCharacterId;
  public faTrash = faTrashAlt;
  public v2 = false;

  constructor(
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private storyTimeService: StoryTimeService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.route.data.subscribe(data => {
      if (data.v2) {
        this.v2 = true;
        storyTimeService.getStoryByIdMinimal(+this.route.snapshot.paramMap.get('storyid')).subscribe(session => {
          this.discordStory = session;
        });
      } else {
        storyTimeService.getStoryByIdMinimal_(+this.route.snapshot.paramMap.get('storyid')).subscribe(session => {
          this.discordStory = session;
        });
      }
    });
  }

  ngOnInit(): void {
    this.characterForm = this.fb.group({
      name: [null, Validators.required],
      image: null,
      age: null,
      gender: null,
      race: null,
      special_power: null,
      personality: null,
      hobbies: null,
      favorite_phrase: null,
      favorite_color: null,
      favorite_food: null
    });
  }

  public onSave() {
    const serverId = this.route.snapshot.paramMap.get('serverid');
    const storyId = this.route.snapshot.paramMap.get('storyid');
    const newCharacter = this.characterForm.getRawValue() as StorytimeCharacter;
    this.storyTimeService.saveCharacter(newCharacter, serverId, storyId, this.v2).subscribe(res => {
      if (res) {
        this.snackbar.open(`${newCharacter.name} joins "${this.discordStory.title}"`, 'close', {
          duration: 3000
        });
        this.characterForm.reset();
        this.reloadCharacters();
      } else {
        this.snackbar.open('Oops... something went wrong', 'close', {
          duration: 3000
        });
      }
    });
  }

  public onEdit() {
    const character = this.characterForm.getRawValue() as StorytimeCharacter;
    character.id = this.currentCharacterId;
    this.storyTimeService.updateCharacter(character, this.v2).subscribe(res => {
      if (res) {
        this.snackbar.open(`${character.name} got an upgrade!`, 'close', {
          duration: 3000
        });
        this.characterForm.reset();
        this.reloadCharacters();
      } else {
        this.snackbar.open('Oops... something went wrong', 'close', {
          duration: 3000
        });
      }
    });
  }

  public onDelete(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'dialog';
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.delete(id);
      }
    });
  }

  private delete(id) {
    this.storyTimeService.deleteCharacter(id, this.v2).subscribe(res => {
      if (res) {
        this.snackbar.open(`character deleted!`, 'close', {
          duration: 3000
        });
        this.reloadCharacters();
      } else {
        this.snackbar.open('Oops... something went wrong', 'close', {
          duration: 3000
        });
      }
    });
  }

  private reloadCharacters() {
    if (this.v2) {
      this.storyTimeService.getStoryByIdMinimal(+this.route.snapshot.paramMap.get('storyid')).subscribe(session => {
        this.discordStory = session;
      });
    } else {
      this.storyTimeService.getStoryByIdMinimal_(+this.route.snapshot.paramMap.get('storyid')).subscribe(session => {
        this.discordStory = session;
      });
    }
  }

  public generateSafeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public getImageUrl(): string {
    return (this.characterForm.getRawValue() as StorytimeCharacter).image;
  }

  public editCharacter(character: StorytimeCharacter) {
    this.characterForm = this.fb.group({
      name: [character.name, Validators.required],
      image: character.image,
      age: character.age,
      gender: character.gender,
      race: character.race,
      special_power: character.special_power,
      personality: character.personality,
      hobbies: character.hobbies,
      favorite_phrase: character.favorite_phrase,
      favorite_color: character.favorite_color,
      favorite_food: character.favorite_food
    });
    this.currentCharacterId = character.id;
  }
}
