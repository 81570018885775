import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {StoryTimeService} from '../../../../services/story-time.service';

@Component({
  selector: 'app-full-story',
  templateUrl: './full-story.component.html',
  styleUrls: ['./full-story.component.scss'],
})
export class FullStoryComponent implements OnInit {

  public story: any;
  public view = 0;
  public v2 = false;

  constructor(
    private route: ActivatedRoute,
    private storyTimeService: StoryTimeService,
  ) {
    this.route.data.subscribe(data => {
      if (data.v2) {
        this.v2 = true;
        storyTimeService.getStoryById(+this.route.snapshot.paramMap.get('id')).subscribe(storyObj => {
          this.story = storyObj;
        });
      } else {
        storyTimeService.getStoryById_(+this.route.snapshot.paramMap.get('id')).subscribe(storyObj => {
          this.story = storyObj;
        });
      }
    });

  }

  ngOnInit(): void {
  }

  public swipeLeft() {
    if (this.view === 0) {
      this.view = 1;
    }
  }

  public swipeRight() {
    if (this.view === 1) {
      this.view = 0;
    }
  }
}
