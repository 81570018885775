import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FishCollectionComponent } from './fish-collection/fish-collection.component';
import {PageComponentsModule} from '../../page-components/page-components.module';
import { FishCardComponent } from './fish-card/fish-card.component';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';



@NgModule({
  declarations: [FishCollectionComponent, FishCardComponent],
  imports: [
    CommonModule,
    PageComponentsModule,
    MatCardModule,
    MatDividerModule
  ]
})
export class HelegoeievissersModule { }
