import {Component, OnInit} from '@angular/core';
import {FishingService} from '../../../../services/fishing.service';
import {Fisherman} from '../../../../entity/fish/fisherman';
import {ActivatedRoute} from '@angular/router';
import {Fish} from '../../../../entity/fish/fish';
import {FishRarityEnum} from '../../../../enums/fish/fish-rarity-enum';

@Component({
  selector: 'app-fish-collection',
  templateUrl: './fish-collection.component.html',
  styleUrls: ['./fish-collection.component.scss']
})
export class FishCollectionComponent implements OnInit {
  public fisherman: Fisherman;
  public junk: Fish[] = [];
  public common: Fish[] = [];
  public uncommon: Fish[] = [];
  public rare: Fish[] = [];
  public epic: Fish[] = [];
  public legendary: Fish[] = [];

  constructor(private fishingService: FishingService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.fishingService.getFisherman(this.route.snapshot.paramMap.get('serverId'), this.route.snapshot.paramMap.get('userId'))
      .subscribe(fisherman => {
        this.fisherman = fisherman;
        this.junk = fisherman.fish_collection.filter(fish => fish.fish_type.fish_type_rarity.rarity === FishRarityEnum.JUNK).sort((a, b) => b.value - a.value);
        this.common = fisherman.fish_collection.filter(fish => fish.fish_type.fish_type_rarity.rarity === FishRarityEnum.COMMON).sort((a, b) => b.value - a.value);
        this.uncommon = fisherman.fish_collection.filter(fish => fish.fish_type.fish_type_rarity.rarity === FishRarityEnum.UNCOMMON).sort((a, b) => b.value - a.value);
        this.rare = fisherman.fish_collection.filter(fish => fish.fish_type.fish_type_rarity.rarity === FishRarityEnum.RARE).sort((a, b) => b.value - a.value);
        this.epic = fisherman.fish_collection.filter(fish => fish.fish_type.fish_type_rarity.rarity === FishRarityEnum.EPIC).sort((a, b) => b.value - a.value);
        this.legendary = fisherman.fish_collection.filter(fish => fish.fish_type.fish_type_rarity.rarity === FishRarityEnum.LEGENDARY).sort((a, b) => b.value - a.value);
      });
  }

}
