<div *ngIf="discordStory">
  <div *ngIf="!discordStory?.nsfw || ignoreNsfw" class="mb-3">
    <mat-card class="discord-darker">
      <mat-card-title>
        <a routerLink="/stories/v2/{{discordStory.id}}" routerLinkActive="active" class="storyCardTitle link">{{discordStory.title}}</a>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content class="p-2 storyCardBody">
        {{displayPage()}}
      </mat-card-content>
      <div class="d-flex flex-row-reverse storyCardText">
        <a *ngIf="!expanded" (click)="loadFullStory()" class="col-md-3 p-0 text-right pointerCursor link">read more</a>
        <a *ngIf="expanded" (click)="collapseStory()" class="col-md-3 p-0 text-right pointerCursor link">collapse</a>
        <span *ngIf="expanded" class="col-md-9">
        <fa-icon *ngIf="page > 0" [icon]="faCaretLeft" (click)="prevPage()" class="enabledIcon pointerCursor"></fa-icon>
        <fa-icon *ngIf="page === 0" [icon]="faCaretLeft" class="disabledIcon"></fa-icon>
          {{page}}/{{maxPage}}
          <fa-icon *ngIf="page < maxPage" [icon]="faCaretRight" (click)="nextPage()"
                   class="enabledIcon pointerCursor"></fa-icon>
        <fa-icon *ngIf="page === maxPage" [icon]="faCaretRight" class="disabledIcon"></fa-icon>
      </span>
      </div>
    </mat-card>
  </div>

  <div *ngIf="discordStory.nsfw && !ignoreNsfw" class="mb-3">
    <mat-card (click)="openNsfwWarning()" class="discord-darkest nsfw-card">
      <mat-card-title>
        <p class="storyCardTitle">{{discordStory.title}}</p>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content class="p-2 nsfw-text mt-5 mb-4">
        NSFW
      </mat-card-content>
    </mat-card>
  </div>
</div>

<!-- DEPRECATED-->
<div *ngIf="story">
  <div *ngIf="!story?.nsfw || ignoreNsfw" class="mb-3">
    <mat-card class="discord-darker">
      <mat-card-title>
        <a routerLink="/stories/{{story.id}}" routerLinkActive="active" class="storyCardTitle link">{{story.title}}</a>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content class="p-2 storyCardBody">
        {{displayPage()}}
      </mat-card-content>
      <div class="d-flex flex-row-reverse storyCardText">
        <a *ngIf="!expanded" (click)="loadFullStory()" class="col-md-3 p-0 text-right pointerCursor link">read more</a>
        <a *ngIf="expanded" (click)="collapseStory()" class="col-md-3 p-0 text-right pointerCursor link">collapse</a>
        <span *ngIf="expanded" class="col-md-9">
        <fa-icon *ngIf="page > 0" [icon]="faCaretLeft" (click)="prevPage()" class="enabledIcon pointerCursor"></fa-icon>
        <fa-icon *ngIf="page === 0" [icon]="faCaretLeft" class="disabledIcon"></fa-icon>
          {{page}}/{{maxPage}}
          <fa-icon *ngIf="page < maxPage" [icon]="faCaretRight" (click)="nextPage()"
                   class="enabledIcon pointerCursor"></fa-icon>
        <fa-icon *ngIf="page === maxPage" [icon]="faCaretRight" class="disabledIcon"></fa-icon>
      </span>
      </div>
    </mat-card>
  </div>

  <div *ngIf="story.nsfw && !ignoreNsfw" class="mb-3">
    <mat-card (click)="openNsfwWarning()" class="discord-darkest nsfw-card">
      <mat-card-title>
        <p class="storyCardTitle">{{story.title}}</p>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content class="p-2 nsfw-text mt-5 mb-4">
        NSFW
      </mat-card-content>
    </mat-card>
  </div>
</div>
