<div class="mb-3">
  <mat-card class="discord-darkest">
    <mat-card-title class="characterCardTitle">
      {{character.name}}
    </mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content class="p-2 characterCardBody">
      <div class="row">
        <div *ngIf="character.image" class="col-md-12">
          <img [src]="generateSafeUrl(character.image)" class="characterCardImage">
        </div>
        <div *ngFor="let property of properties; let i = index" class="col-md-12 mt-2">
          <div>
            <label>{{labels[i]}}</label>
            {{property ? property : '-'}}
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
