import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-steam',
  templateUrl: './logo-steam.component.html',
  styleUrls: ['./logo-steam.component.scss', '../logo-base/logo-base.component.scss']
})
export class LogoSteamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
