<div class="p-2">
  <mat-card class="discord-darkest">
    <mat-card-title class="fishCardTitle">
      {{fish.is_jumbo ? 'Jumbo ': ''}}{{fish.fish_type.name}}{{fish.is_jumbo ? '☆': ''}}
    </mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content class="p-2 fishCardBody">
      <div class="row">
        <div class="col-md-12">
          <img [src]=getImage(fish) class="fishCardImage">
        </div>
        <div class="col-md-12 mt-2">
          <div>
            <label>Weight: </label>
            {{fish.weight}} lb
          </div>
          <div>
            <label>Value: </label>
            {{fish.value}} ƒ฿
          </div>
          <div>
            <label>Catch date: </label>
            {{fish.catch_date.toLocaleDateString('nl-NL', {  year: 'numeric', month: 'short', day: 'numeric' })}}
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
