import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoriesComponent} from './stories/stories.component';
import {StoryCardComponent} from './story-card/story-card.component';
import {MaterialModule} from '../../../materials.module';
import {FullStoryComponent} from './full-story/full-story.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RouterModule} from '@angular/router';
import {CharacterCardComponent} from './character-card/character-card.component';
import {PageComponentsModule} from '../../page-components/page-components.module';
import {MatDialogModule} from '@angular/material/dialog';
import {NsfwWarningComponent} from './nsfw-warning/nsfw-warning.component';
import { CreateCharacterComponent } from './create-character/create-character.component';
import {ReactiveFormsModule} from '@angular/forms';
import { StoryTimeMainComponent } from './story-time-main/story-time-main.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { NewEntryComponent } from './new-entry/new-entry.component';
import { FinishStoryWarningComponent } from './finish-story-warning/finish-story-warning.component';


@NgModule({
  declarations: [StoriesComponent, StoryCardComponent, FullStoryComponent, CharacterCardComponent, NsfwWarningComponent, CreateCharacterComponent, StoryTimeMainComponent, DeleteConfirmationComponent, NewEntryComponent, FinishStoryWarningComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FontAwesomeModule,
    RouterModule,
    PageComponentsModule,
    MatDialogModule,
    ReactiveFormsModule
  ],
  exports: [
    StoriesComponent
  ],
  entryComponents: [NsfwWarningComponent]
})
export class HelegoeieverhalenModule {
}
