<div class="row text-center text-white mt-5">
  <h1 class="col-12">Welcome to the HelegoeieDiscordBots website!</h1>
  <span class="col-12">Entertainment and utility bots for your discord server</span>
  <div class="row mt-5 col-12 justify-content-center">
    <div class="col-md-3 col-12 mt-3">
      <app-logo-story [routerLink]="['/stories']" class="lp-logo col-12"></app-logo-story>
      <h3 class="col-12">Story Time Bot</h3>
      <button [routerLink]="['/stories']" mat-raised-button class="text-white story-pink">Learn more</button>
    </div>
    <div class="col-md-3 col-12 mt-3">
      <app-logo-dice [routerLink]="['/dice']" class="lp-logo"></app-logo-dice>
      <h3 class="col-12">Tabletop Dice bot</h3>
      <button [routerLink]="['/dice']" mat-raised-button class="text-white dice-red">Learn more</button>
    </div>
    <div class="col-md-3 col-12 mt-3 mb-5">
      <app-logo-steam [routerLink]="['/steam']" class="lp-logo"></app-logo-steam>
      <h3 class="col-12">Steam Deals bot</h3>
      <button [routerLink]="['/steam']" mat-raised-button class="text-white steam-marine">Learn more</button>
    </div>
  </div>
</div>
