import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-story',
  templateUrl: './logo-story.component.html',
  styleUrls: ['./logo-story.component.scss', '../logo-base/logo-base.component.scss']
})
export class LogoStoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
