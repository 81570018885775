<div class="row">
  <div class="col-md-4 col-12 mt-2 logo">
    <app-logo-story></app-logo-story>
    <div class="col-12">
      <button id="inviteBtn" mat-raised-button (click)="clickInvite()">Invite me!</button>
    </div>
  </div>
  <div class="col-md-6 col-12 mt-3">
    <div *ngFor="let story of concludedStories">
      <app-story-card [discordStory]="story"></app-story-card>
    </div>
    <div *ngFor="let story of concludedStories_">
      <app-story-card [story]="story"></app-story-card>
    </div>
  </div>
</div>

