<nav class="navbar navbar-expand-lg navbar-dark"
     [ngClass]="matchesUrl('/stories') ? 'story-pink' : (matchesUrl('/steam')? 'steam-marine': (matchesUrl('/dice')? 'dice-red' : 'discord-blurple'))">
  <a class="navbar-brand" href="#">
    HeleGoeieDiscordBots
  </a>

  <button (click)="collapsed = !collapsed" class="navbar-toggler" type="button">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse">
    <ul class="navbar-nav mr-auto">
      <li>
        <a routerLink="/stories" routerLinkActive="active" class="nav-link">Story Time Bot</a>
      </li>
      <li>
        <a routerLink="/dice" routerLinkActive="active" class="nav-link">Tabletop Dice Bot</a>
      </li>
      <li>
        <a routerLink="/steam" routerLinkActive="active" class="nav-link">Steam Deals Bot</a>
      </li>
    </ul>
  </div>

  <div *ngIf="!collapsed" class="mobile-only collapse show navbar-collapse" id="navbarNav">
    <ul class="mobile-only navbar-nav">
      <li>
        <a routerLink="/stories" routerLinkActive="active" class="nav-link">Story Time Bot</a>
      </li>
      <li>
        <a routerLink="/dice" routerLinkActive="active" class="nav-link">Tabletop Dice Bot</a>
      </li>
      <li>
        <a routerLink="/steam" routerLinkActive="active" class="nav-link">Steam Deals Bot</a>
      </li>
    </ul>
  </div>
</nav>
