import {Component, Input, OnInit} from '@angular/core';
import {StorytimeCharacter} from '../../../../entity/storytime/storytime-character';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-character-card',
  templateUrl: './character-card.component.html',
  styleUrls: ['./character-card.component.scss']
})
export class CharacterCardComponent implements OnInit {

  public properties;
  public labels = [
    'Age:', 'Gender:', 'Race:', 'Special power:', 'Personality:', 'Hobbies:', 'Catchphrase:', 'Favourite food:', 'Favourite colour:'
  ];

  @Input()
  public character: StorytimeCharacter;

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.properties = [
      this.character.age, this.character.gender, this.character.race,
      this.character.special_power, this.character.personality, this.character.hobbies, this.character.favorite_phrase,
      this.character.favorite_food, this.character.favorite_color
    ];
  }

  public generateSafeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
