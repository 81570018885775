<div *ngIf="!storyEntry && !loading">
  <div class="row justify-content-center">
    <h1 class="text-white mt-5">Write your story entry here!</h1>
  </div>
  <div class="row justify-content-center px-5">
    <p class="text-white mt-2">It is your turn to continue this story! You can end the current story at any time by
      clicking on the Submit & End button</p>
  </div>
  <div class="row justify-content-center">
    <textarea class="col-10 col-md-8 mt-5 discord-not-as-dark text-white" maxlength="2000" #entree></textarea>
  </div>
  <div class="row justify-content-center">
    <button mat-raised-button class="col-4 col-md-3 mt-5 mx-3 discord-blurple text-white"
            (click)="submitStory(entree.value)">Submit
    </button>
    <button mat-raised-button class="col-4 col-md-3 mt-5 mx-3 discord-gray text-white"
            (click)="submitAndEndStory(entree.value)">Submit & End
    </button>
  </div>
</div>

<div *ngIf="storyEntry">
  <div class="row justify-content-center">
    <h1 class="text-white mt-5">Your entree has been submitted!</h1>
  </div>
  <div class="row justify-content-center">
    <p class="text-white mt-5 col-10">{{storyEntry.text}}</p>
  </div>
</div>
