import {Component, Input, OnInit} from '@angular/core';
import {ActiveStoryTimeServer} from '../../../../entity/storytime/active-storytime-server';
import {StoryTimeService} from '../../../../services/story-time.service';
import {faCaretLeft} from '@fortawesome/free-solid-svg-icons/faCaretLeft';
import {faCaretRight} from '@fortawesome/free-solid-svg-icons/faCaretRight';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {NsfwWarningComponent} from '../nsfw-warning/nsfw-warning.component';
import {DiscordStory} from '../../../../entity/storytime/discord-story';

@Component({
  selector: 'app-story-card',
  templateUrl: './story-card.component.html',
  styleUrls: ['./story-card.component.scss']
})
export class StoryCardComponent implements OnInit {

  @Input()
  public discordStory: DiscordStory;

  @Input()
  public story: ActiveStoryTimeServer;

  public expanded = false;
  public page: number;
  public maxPage: number;
  public pageSize: number;
  public ignoreNsfw = false;
  public storyText = '';

  private fullStoryLoaded = false;

  public faCaretLeft = faCaretLeft;
  public faCaretRight = faCaretRight;


  constructor(
    private storyTimService: StoryTimeService,
    private dialog: MatDialog
  ) {
    this.page = 0;
    this.pageSize = 200;
  }

  ngOnInit(): void {
    if (this.discordStory) {
      this.discordStory.entries.forEach(entry => this.storyText += entry.text + ' ');
    } else {
      this.storyText = this.story.full_story;
    }
  }

  public loadFullStory() {
    if (!this.fullStoryLoaded) {
      if (this.discordStory){
        this.storyTimService.getFullDiscordStory(this.discordStory.id).subscribe(fullStory => {
          this.storyText = fullStory.content;
          this.expanded = true;
          this.maxPage = Math.floor(this.storyText.split(' ').length / this.pageSize);
          this.fullStoryLoaded = true;
        });
      } else {
        this.storyTimService.getFullStory(this.story.id).subscribe(fullStory => {
          this.storyText = fullStory.content;
          this.expanded = true;
          this.maxPage = Math.floor(this.storyText.split(' ').length / this.pageSize);
          this.fullStoryLoaded = true;
        });
      }
    } else {
      this.expanded = true;
    }
  }

  public displayPage(): string {
    if (this.expanded) {
      return this.getWords(this.storyText, this.page * this.pageSize, (this.page + 1) * this.pageSize);
    } else {
      return this.storyText.substring(0, 500) + '...';
    }
  }

  private getWords(input: string, from: number, to: number): string {
    const words = input.split(' ');
    return words.slice(from, to).join(' ');
  }

  public collapseStory() {
    this.expanded = false;
  }

  public prevPage() {
    this.page--;
  }

  public nextPage() {
    this.page++;
  }

  public openNsfwWarning() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'dialog';
    const dialogRef = this.dialog.open(NsfwWarningComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.ignoreNsfw = true;
      }
    });
  }
}
