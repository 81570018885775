import {Component, OnInit} from '@angular/core';
import {StoryTimeService} from '../../../../services/story-time.service';
import {ActivatedRoute} from '@angular/router';
import {BotService} from '../../../../services/bot.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FinishStoryWarningComponent} from '../finish-story-warning/finish-story-warning.component';
import {StoryEntry} from '../../../../entity/storytime/story-entry';

@Component({
  selector: 'app-new-entry',
  templateUrl: './new-entry.component.html',
  styleUrls: ['./new-entry.component.scss']
})
export class NewEntryComponent implements OnInit {

  private readonly sessionId;
  private readonly writersKey;
  private readonly serverId;
  public storyEntry: StoryEntry;
  public loading = true;


  constructor(private storyTimeService: StoryTimeService,
              private botService: BotService,
              private activatedRoute: ActivatedRoute,
              private snackbar: MatSnackBar,
              private dialog: MatDialog) {
    this.sessionId = this.activatedRoute.snapshot.params.sessionId;
    this.writersKey = this.activatedRoute.snapshot.params.writersKey;
    this.serverId = this.activatedRoute.snapshot.params.serverId;
    this.storyTimeService.getEntryByKey(this.writersKey).subscribe(res => {
      this.storyEntry = res;
      this.loading = false;
    });
  }

  ngOnInit(): void {
  }

  submitStory(entry: string) {
    this.storyTimeService.submitEntry(entry, this.sessionId, this.writersKey).subscribe(res => {
      if (res.server_id && res.server_id === this.serverId) {
        this.botService.invokeBotAfterStorySubmit(res.server_id).subscribe(() => {
          window.location.reload();
        });
      }
    }, error => {
      this.snackbar.open(error.error.message, 'close', {
        duration: 3000
      });
    });
  }

  submitAndEndStory(entry: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'dialog';
    const dialogRef = this.dialog.open(FinishStoryWarningComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.submitStory(entry + '\n[THE END]');
      }
    });
  }
}
