import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HelegoeieverhalenModule} from './pages/modules/helegoeieverhalen/helegoeieverhalen.module';
import {HttpClientModule} from '@angular/common/http';
import {LandingPageComponent} from './pages/landing-page/landing-page.component';
import {HeaderComponent} from './pages/header/header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {PageComponentsModule} from './pages/page-components/page-components.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HelegoeiedobbelstenenModule} from './pages/modules/helegoeiedobbelstenen/helegoeiedobbelstenen.module';
import {HelegoeiesteamdealsModule} from './pages/modules/helegoeiesteamdeals/helegoeiesteamdeals.module';
import {MatButtonModule} from '@angular/material/button';
import {HelegoeievissersModule} from './pages/modules/helegoeievissers/helegoeievissers.module';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HelegoeieverhalenModule,
    HelegoeiedobbelstenenModule,
    HelegoeiesteamdealsModule,
    HelegoeievissersModule,
    HttpClientModule,
    FontAwesomeModule,
    PageComponentsModule,
    BrowserAnimationsModule,
    MatButtonModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
