/* tslint:disable:object-literal-key-quotes */
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ActiveStoryTimeServer} from '../entity/storytime/active-storytime-server';
import {paths} from '../../apiconfig';
import {StorytimeCharacter} from '../entity/storytime/storytime-character';
import {DiscordStory} from '../entity/storytime/discord-story';
import {StoryEntry} from '../entity/storytime/story-entry';

@Injectable({
  providedIn: 'root'
})
export class StoryTimeService {
  private corsHeaders: HttpHeaders;

  constructor(
    private httpClient: HttpClient
  ) {
    this.corsHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '/'
    });
  }

  public getAllStories_(): Observable<ActiveStoryTimeServer[]> {
    return this.httpClient.get(`${paths.baseURL}${paths.story.getAllStories}`, {headers: this.corsHeaders}) as Observable<ActiveStoryTimeServer[]>;
  }

  public getAllStories(): Observable<DiscordStory[]> {
    return this.httpClient.get(`${paths.baseURL}/v2${paths.story.getAllStories}`, {headers: this.corsHeaders}) as Observable<DiscordStory[]>;
  }

  public getFullStory(id: number) {
    return this.httpClient.get(`${paths.baseURL}${paths.story.getFullStory}/${id}`, {headers: this.corsHeaders}) as Observable<any>;
  }

  public getFullDiscordStory(id: number) {
    return this.httpClient.get(`${paths.baseURL}/v2${paths.story.getFullStory}/${id}`, {headers: this.corsHeaders}) as Observable<any>;
  }

  public getStoryById_(id: number): Observable<ActiveStoryTimeServer> {
    return this.httpClient.get(`${paths.baseURL}${paths.story.getStoryById}/${id}`, {headers: this.corsHeaders}) as Observable<ActiveStoryTimeServer>;
  }

  public getStoryById(id: number): Observable<DiscordStory> {
    return this.httpClient.get(`${paths.baseURL}/v2${paths.story.getStoryById}/${id}`, {headers: this.corsHeaders}) as Observable<DiscordStory>;
  }

  public saveCharacter(character: StorytimeCharacter, serverId: string, storyId: string, v2: boolean) {
    if (v2) {
      return this.httpClient.post(`${paths.baseURL}/v2${paths.story.createCharacter}/${serverId}/${storyId}`, character, {headers: this.corsHeaders}) as Observable<any>;
    } else {
      return this.httpClient.post(`${paths.baseURL}${paths.story.createCharacter}/${serverId}/${storyId}`, character, {headers: this.corsHeaders}) as Observable<any>;
    }
  }

  public updateCharacter(character: StorytimeCharacter, v2: boolean) {
    if (v2) {
      return this.httpClient.post(`${paths.baseURL}/v2${paths.story.updateCharacter}`, character, {headers: this.corsHeaders}) as Observable<any>;
    } else {
      return this.httpClient.post(`${paths.baseURL}${paths.story.updateCharacter}`, character, {headers: this.corsHeaders}) as Observable<any>;
    }
  }

  public getStoryByIdMinimal_(id: number): Observable<ActiveStoryTimeServer> {
    return this.httpClient.get(`${paths.baseURL}${paths.story.getStoryByIdMinimal}/${id}`, {headers: this.corsHeaders}) as Observable<ActiveStoryTimeServer>;
  }

  public getStoryByIdMinimal(id: number): Observable<DiscordStory> {
    return this.httpClient.get(`${paths.baseURL}/v2${paths.story.getStoryByIdMinimal}/${id}`, {headers: this.corsHeaders}) as Observable<DiscordStory>;
  }

  public deleteCharacter(id: number, v2: boolean) {
    if (v2) {
      return this.httpClient.get(`${paths.baseURL}/v2${paths.story.removeCharacter}/${id}`, {headers: this.corsHeaders}) as Observable<any>;
    } else {
      return this.httpClient.get(`${paths.baseURL}${paths.story.removeCharacter}/${id}`, {headers: this.corsHeaders}) as Observable<any>;
    }
  }

  public submitEntry(entree: string, sessionId: any, userId: any): Observable<DiscordStory> {
    return this.httpClient.post(`${paths.baseURL}/submit-entree/${sessionId}/${userId}`, {entree}, {headers: this.corsHeaders}) as Observable<DiscordStory>;
  }

  public getEntryByKey(writersKey: string): Observable<StoryEntry> {
    return this.httpClient.get(`${paths.baseURL}/fetch-entry-by-key/${writersKey}`, {headers: this.corsHeaders}) as Observable<StoryEntry>;
  }
}
