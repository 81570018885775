<h3>Are you sure you want to delete this character?</h3>
<p>This action is not reversible</p>
<div class="row">
  <div class="col-1"></div>
  <button [mat-dialog-close]="true" mat-button class="col-3 discord-blurple">
    Yes
  </button>
  <div class="col-4"></div>
  <button [mat-dialog-close]="false" mat-button class="col-3 discord-gray">
    No
  </button>
</div>
