<div class="row">
  <div class="col-md-4 col-12 mt-2 logo">
    <app-logo-steam></app-logo-steam>
    <div class="col-12">
      <button id="inviteBtn" mat-raised-button (click)="clickInvite()">Invite me!</button>
    </div>
  </div>
  <div class="col-md-6 col-12 mt-3 body-txt px-md-0 px-4">
    <h1 class="p-1">Hi! I can be a great addition to your discord server</h1>
    <p class="p-1">Let me start by introducing myself. My name is <em>Steam Deals Bot</em>, or <em>HeleGoeieSteamDeals</em>. If you invite me I will visit your server every day to tell you about all discounted steam games. It's basically Christmas, but everyday!</p>

    <div class="row justify-content-center">
      <div class="col-md-9 col-12 mt-5 body-txt">
        <h2>How does it work?</h2>
        <p>There are only two commands! First you choose the text channel that you want me to visit each day. Here you type: <em>.steam subscribe</em>. Now I will post all the deals that I find into this channel every day a 10:00 GMT +1. If you want to cancel a channels subscription, just type <em>.steam unsubscribe</em>.</p>
      </div>
    </div>

    <div class="row justify-content-center">
      <img class="my-5 w-50 no-mobile" src="assets/steam-example.png">
      <img class="my-5 w-100 mobile-only" src="assets/steam-example.png">
    </div>

  </div>
</div>

