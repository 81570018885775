import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-nsfw-warning',
  templateUrl: './nsfw-warning.component.html',
  styleUrls: ['./nsfw-warning.component.scss']
})
export class NsfwWarningComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
