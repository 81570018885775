import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {StoriesComponent} from './pages/modules/helegoeieverhalen/stories/stories.component';
import {LandingPageComponent} from './pages/landing-page/landing-page.component';
import {FullStoryComponent} from './pages/modules/helegoeieverhalen/full-story/full-story.component';
import {CreateCharacterComponent} from './pages/modules/helegoeieverhalen/create-character/create-character.component';
import {SteamDealMainComponent} from './pages/modules/helegoeiesteamdeals/steam-deal-main/steam-deal-main.component';
import {DiceMainComponent} from './pages/modules/helegoeiedobbelstenen/dice-main/dice-main.component';
import {StoryTimeMainComponent} from './pages/modules/helegoeieverhalen/story-time-main/story-time-main.component';
import {NewEntryComponent} from './pages/modules/helegoeieverhalen/new-entry/new-entry.component';
import {FishCollectionComponent} from './pages/modules/helegoeievissers/fish-collection/fish-collection.component';


const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent
  },

  {
    path: 'fish',
    children: [
      {
        path: 'collection/:serverId/:userId',
        component: FishCollectionComponent
      }
    ]
  },

  {
    path: 'steam',
    children: [
      {
        path: '',
        component: SteamDealMainComponent
      }
    ]
  },

  {
    path: 'dice',
    children: [
      {
        path: '',
        component: DiceMainComponent
      }
    ]
  },

  {
    path: 'stories',
    children: [
      {
        path: '',
        component: StoryTimeMainComponent
      },
      {
        path: 'all',
        component: StoriesComponent
      },
      {
        path: ':id',
        component: FullStoryComponent
      },
      {
        path: 'v2/:id',
        component: FullStoryComponent,
        data: {v2: true}
      },
      {
        path: 'new-entry/:serverId/:sessionId/:writersKey',
        component: NewEntryComponent,
      },
      {
        path: 'characters',
        children: [
          {
            path: 'create/:serverid/:storyid',
            component: CreateCharacterComponent
          },
          {
            path: 'create/v2/:serverid/:storyid',
            component: CreateCharacterComponent,
            data: {v2: true}
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
