<div class="row">
  <div class="col-md-4 col-12 mt-2 logo">
    <app-logo-dice></app-logo-dice>
    <div class="col-12">
      <button id="inviteBtn" mat-raised-button (click)="clickInvite()">Invite me!</button>
    </div>
  </div>
  <div class="col-md-6 col-12 mt-3 body-txt px-md-0 px-4">
    <h1 class="p-1">Hi! I can be a great addition to your discord server</h1>
    <p class="p-1">Let me start by introducing myself. My name is <em>Tabletop Dice Bot</em>, or <em>HeleGoeieDobbelstenen</em>.
      I'm a utility bot that can simulate polyhedral dice throws. If you would like to send me an invite, please click
      the <em>brown</em> button</p>

    <div class="row justify-content-center">
      <div class="col-md-9 col-12 mt-5 body-txt">
        <h2>How does it work?</h2>
        <p>It's very simple! every command starts with <em>.d</em> followed by the type of dice you want to throw. As an
          example: <em>.d20</em> will throw a single 20-sided dice. Optionally you can also specify the amount of dice
          you want to throw. So <em>.d4 3</em> will simulate 3 4-sided die throws.</p>
      </div>
    </div>

    <div class="row justify-content-center">
      <img class="my-5 w-50 no-mobile" src="assets/dice-example.png">
      <img class="my-5 w-100 mobile-only" src="assets/dice-example.png">
    </div>

  </div>
</div>

