/* tslint:disable:object-literal-key-quotes */
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ActiveStoryTimeServer} from '../entity/storytime/active-storytime-server';
import {paths} from '../../apiconfig';
import {Fisherman} from '../entity/fish/fisherman';

@Injectable({
  providedIn: 'root'
})
export class FishingService {
  private corsHeaders: HttpHeaders;

  constructor(
    private httpClient: HttpClient
  ) {
    this.corsHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '/'
    });
  }

  public getFisherman(serverId, userId): Observable<Fisherman> {
    return this.httpClient.get(`${paths.baseURL}${paths.fish.collection}/${serverId}/${userId}`, {headers: this.corsHeaders}) as Observable<Fisherman>;
  }

}
