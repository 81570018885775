import {Component, ElementRef, OnInit} from '@angular/core';
import {StoryTimeService} from '../../../../services/story-time.service';
import {ActiveStoryTimeServer} from '../../../../entity/storytime/active-storytime-server';
import {DiscordStory} from '../../../../entity/storytime/discord-story';

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss']
})
export class StoriesComponent implements OnInit {

  private inviteLink = 'https://discord.com/api/oauth2/authorize?client_id=695265695755403315&permissions=93184&scope=bot';

  public concludedStories: DiscordStory[];
  public concludedStories_: ActiveStoryTimeServer[];

  constructor(
    private storyTimeService: StoryTimeService,
  ) {
  }

  ngOnInit(): void {
    this.storyTimeService.getAllStories().subscribe(stories => {
      console.log(stories);
      this.concludedStories = stories;
    });
    this.storyTimeService.getAllStories_().subscribe(stories => {
      this.concludedStories_ = stories;
    });
  }

  public clickInvite() {
    window.open(this.inviteLink, '_blank');
  }
}
