import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-finish-story-warning',
  templateUrl: './finish-story-warning.component.html',
  styleUrls: ['./finish-story-warning.component.scss']
})
export class FinishStoryWarningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
