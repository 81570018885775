import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ActiveStoryTimeServer} from '../entity/storytime/active-storytime-server';
import {paths} from '../../apiconfig';

@Injectable({
  providedIn: 'root'
})
export class BotService {

  private corsHeaders: HttpHeaders;

  constructor(
    private httpClient: HttpClient
  ) {
    this.corsHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '/'
    });
  }

  public invokeBotAfterStorySubmit(serverId): Observable<any> {
    return this.httpClient.get(`${paths.baseBotURL}/entry-submitted/${serverId}`, {headers: this.corsHeaders}) as Observable<any>;
  }

}
