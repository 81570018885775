import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiceMainComponent } from './dice-main/dice-main.component';
import {PageComponentsModule} from '../../page-components/page-components.module';
import {MaterialModule} from '../../../materials.module';



@NgModule({
  declarations: [DiceMainComponent],
  imports: [
    CommonModule,
    PageComponentsModule,
    MaterialModule
  ]
})
export class HelegoeiedobbelstenenModule { }
