<h3>You are about to finish this story</h3>
<p>Do you want to continue?</p>
<div class="row">
  <div class="col-1"></div>
  <button [mat-dialog-close]="true" mat-button class="col-3 discord-blurple">
    Yes
  </button>
  <div class="col-4"></div>
  <button [mat-dialog-close]="false" mat-button class="col-3 discord-gray">
    No
  </button>
</div>
