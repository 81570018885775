import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SteamDealMainComponent } from './steam-deal-main/steam-deal-main.component';
import {PageComponentsModule} from '../../page-components/page-components.module';
import {MaterialModule} from '../../../materials.module';



@NgModule({
  declarations: [SteamDealMainComponent],
  imports: [
    CommonModule,
    PageComponentsModule,
    MaterialModule
  ]
})
export class HelegoeiesteamdealsModule { }
