<div class="row p-4 no-mobile">
  <div *ngIf="story" class="col-md-8">
    <mat-card class="discord-darkest">
      <mat-card-title class="storyCardTitle">
        {{story.title}}
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content class="p-2 storyCardBody" *ngIf="!v2">
        {{story.full_story}}
      </mat-card-content>
      <mat-card-content class="p-2 storyCardBody" *ngIf="v2">
        <p *ngFor="let entry of story.entries" class="story-paragraph" [matTooltip]="entry.author_name">
          {{entry.text}}
        </p>
      </mat-card-content>
    </mat-card>
  </div>

  <div *ngIf="story" class="col-md-3">
    <div *ngFor="let character of story.characters">
      <app-character-card [character]="character"></app-character-card>
    </div>
  </div>
</div>

<div class="row p-4 mobile-only stickybox"
>
  <div (click)="swipeLeft()" *ngIf="story && view === 0" class="swipetip mb-3 col-12 pt-1">
    Go to characters
  </div>
  <div *ngIf="story && view === 0;" class="col-12">
    <mat-card class="discord-darkest">
      <mat-card-title class="storyCardTitle">
        {{story.title}}
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content class="p-2 storyCardBody" *ngIf="!v2">
        {{story.full_story}}
      </mat-card-content>
      <mat-card-content class="p-2 storyCardBody" *ngIf="v2">
        <p *ngFor="let entry of story.entries">
          {{entry.text}}
        </p>
      </mat-card-content>
    </mat-card>
  </div>
  <div (click)="swipeRight()" *ngIf="story && view === 1" class="swipetip mb-3 col-12 pt-1">
    Go to story
  </div>
  <div *ngIf="story && view === 1" class="mt-2 col-10 offset-1">
    <div *ngFor="let character of story.characters">
      <app-character-card [character]="character"></app-character-card>
    </div>
  </div>
</div>
