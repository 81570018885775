<div class="row">
  <div class="col-md-4 col-12 mt-2 logo">
    <app-logo-story></app-logo-story>
    <div class="col-12">
      <button id="inviteBtn" mat-raised-button (click)="clickInvite()">Invite me!</button>
    </div>
  </div>
  <div class="col-md-6 col-12 mt-3 body-txt px-md-0 px-4">
    <h1 class="p-1">Hi! I can be a great addition to your discord server</h1>
    <p class="p-1">Let me start by introducing myself. My name is <em>Story Time Bot</em>, or <em>HeleGoeieVerhalen</em>,
      and my goal is to help you and your friends write an epic story! Together we could create an enticing tale about a
      dragon that has to save a tower from an evil princess; or maybe a novella about a misunderstood sailor that wanted
      to save a drowning whale. Do all these fantasy tropes not tickle your personal creativity? No problem! You decide
      what you want to write. If I managed to get you interested (I sure hope I did), keep on reading below or invite me
      by clicking on the <em>pink</em> button!
    </p>

    <div class="row">
      <div class="col-md-6 col-12 mt-5 body-txt">
        <h2>How does it work?</h2>
        <p>Easy! After inviting the bot to your server you can initialize a
          <em>story time session</em>
          with the command <em>/tale init</em>. Now anyone in the server can join the session by typing <em>/tale
            join</em>. The last command to remember is <em>/tale start</em> followed by the title of your new adventure,
          this will begin the session with all the members that joined. That's it! I will pick a random member who can
          start the story. When it's your turn to write, I will a special link to this website where you can write your
          epic entry. Whenever anyone feels like it is time to finish the story, you can do so by clicking on the submit
          and end button.
        </p>
      </div>
      <div class="col-md-6 col-12 mt-5 body-txt">
        <h2>Sooo... is that all?</h2>
        <p>Certainly not! There are a few other commands that help make the process more streamlined. <em>/tale who</em>
          will display the current writer in the chat and <em>/tale terminate</em> will completely destroy the session
          if something didn't go as planned. Also, if a session turned out the be a bit more steamy that anticipated,
          you can mark your session as not safe for work with <em>/tale nsfw</em>. But the best feature in my opinion is
          the possibility to breath life into original characters for your story. more on this below.</p>
      </div>
      <div class="col-12 mt-5 body-txt">
        <h2>Character creation</h2>
        <p>After you type <em>/tale addChar</em> in the channel, I will generate a special link for your current
          session.
          With this, you create characters for your story. You can give them a name and define their likes and
          personality. You can also grant them a special, or not so special, power. To top it of, you can provide an
          image url to give your creation some shape.</p>
      </div>
      <div class="col-12 my-5">
        <button mat-raised-button routerLink="/stories/all" routerLinkActive="active"
                class="discord-blurple text-white w-75">Go to stories
        </button>
      </div>
    </div>

  </div>
</div>
