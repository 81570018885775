<div class="row mt-3">
  <div class="col-md-6 col-12 p-4">
    <mat-card class="discord-darker col-12 p-5">
      <form [formGroup]="characterForm" class="col-md-12">

        <div class="row">
          <mat-form-field>
            <input matInput formControlName="name" placeholder="Name*" autocomplete="off">
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field>
            <input matInput formControlName="image" placeholder="ImageUrl" autocomplete="off">
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field>
            <input matInput formControlName="age" placeholder="Age" autocomplete="off">
          </mat-form-field>
        </div>

        <mat-radio-group class="row text-discord-blurple" formControlName="gender">
          <p>Gender</p>
          <div class="col-12"></div>
          <mat-radio-button class="col-4" name="gender" value="male">male</mat-radio-button>
          <mat-radio-button class="col-4" name="gender" value="female">female</mat-radio-button>
          <mat-radio-button [checked]="true" class="col-4" name="gender" value="undefined">undefined
          </mat-radio-button>
        </mat-radio-group>

        <div class="row">
          <mat-form-field>
            <input matInput formControlName="race" placeholder="Race" autocomplete="off">
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field>
            <input matInput formControlName="special_power" placeholder="Special Power" autocomplete="off">
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field>
            <input matInput formControlName="personality" placeholder="Personality" autocomplete="off">
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field>
            <input matInput formControlName="hobbies" placeholder="Hobbies" autocomplete="off">
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field>
            <input matInput formControlName="favorite_phrase" placeholder="Catchphrase" autocomplete="off">
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field>
            <input matInput formControlName="favorite_color" placeholder="Favourite Colour" autocomplete="off">
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field>
            <input matInput formControlName="favorite_food" placeholder="Favourite Food" autocomplete="off">
          </mat-form-field>
        </div>

        <div class="row mt-3">
          <button (click)="onSave()" *ngIf="characterForm.valid" mat-button
                  class="discord-blurple text-white mr-3 mb-2" type="submit">Save as new character
          </button>
          <button (click)="onEdit()" *ngIf="characterForm.valid && currentCharacterId" mat-button
                  class="discord-blurple text-white mb-2" type="submit">Edit existing character
          </button>
          <button *ngIf="!characterForm.valid" mat-button class="discord-gray text-white mr-3 mb-2" type="submit"
                  [disabled]="true">Save as new character
          </button>
          <button *ngIf="!characterForm.valid || !currentCharacterId" mat-button class="discord-gray text-white mb-2"
                  type="submit"
                  [disabled]="true">Edit existing character
          </button>
        </div>
      </form>
    </mat-card>
  </div>
  <div class="col-md-4 col-12 offset-md-1 discord-dark p-4">
    <mat-card class="discord-darkest mb-3 imageContainer">
      <img *ngIf="getImageUrl()" [src]="generateSafeUrl(getImageUrl())" class="charImage col-12">
    </mat-card>
    <div *ngFor="let character of discordStory?.characters" class="row">
      <fa-icon (click)="onDelete(character.id)" class="text-discord-blurple col-1 delete-icon mr-2 fa-2x" [icon]="faTrash"></fa-icon>
      <mat-card (click)="editCharacter(character)"
                class="charCard discord-darkest text-discord-blurple mb-2 col-10">
        <h3>{{character.name}}</h3>
      </mat-card>
    </div>
  </div>
</div>
