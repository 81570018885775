import {Component, Input, OnInit} from '@angular/core';
import {Fish} from '../../../../entity/fish/fish';

@Component({
  selector: 'app-fish-card',
  templateUrl: './fish-card.component.html',
  styleUrls: ['./fish-card.component.scss']
})
export class FishCardComponent implements OnInit {

  @Input()
  public fish: Fish;

  constructor() {
  }

  ngOnInit(): void {
    this.fish.catch_date = new Date(this.fish.catch_date);
  }

  getImage(fish: Fish) {
    return `https://helegoeiediscordbots.nl/assets/fish/${fish.fish_type.name.toLocaleLowerCase().split(' ').join('_')}.png`;
  }
}
